import '../../webpack.base';
import '../css/login.css';
import 'mcui/extensions';
import 'i18n/webcore';
import 'i18n/mixins/backbone';
import '../lib/vendor/parsley';
import $ from 'jquery';
import LoginView from './login/views/loginView';
import LanguageSelectionView from './shared/views/languageSelectionView';

$(function() {
  new LoginView({
    el: $('#login')
  });
  new LanguageSelectionView({
    el: $('footer'),
    languages: window.languages
  });
});
