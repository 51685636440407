/*globals define*/
/*globals define*/
/*globals define*/
/*globals define*/
import Backbone from 'backbone';

export default Backbone.View.extend({
    initialize: function () {
        this.render();
    },
    events: {
        'submit form': 'handleFormSubmit'
    },
    buttonLoaderClasses: 'loader loader--green loader--btn',
    formSubmitted: false,
    handleFormSubmit: function () {
        if (!this.formSubmitted) {
            this.formSubmitted = true;
            this.showFormErrors();
            this.$el.find('button[type="submit"]').addClass(this.buttonLoaderClasses);
        } else {
            // Prevent the user from submitting the form more than once
            return false;
        }
    },
    showFormErrors: function () {
        this.$el.find('form').removeClass('form--hideerrors');
    },
    preloadImages: function () {
        var spinner = new Image();
        spinner.src = "/static/webcore/lib/mcb/img/loader-green.gif";
    },
    render: function () {
        var self = this;

        if (this.options.template) this.$el.html(this.options.template);

        this.preloadImages();

        var $form = self.$el.find('form');

        if(/[?&]ui=([^&]*)/.test(window.location.search)) {
            var theme = RegExp.$1;
            var url = document.createElement('a');
            url.href = $form.prop('action');
            url.search =  url.search
                ? url.search + '&ui=' + encodeURIComponent(theme)
                : 'ui=' + encodeURIComponent(theme);
            $form.prop('action', url.href);
        }

        // Validate password set/reset form
        $form.parsley({
            successClass: 'field-valid',
            errorClass: 'field-error',
            listeners: {
                onFieldValidate: function (elem, ParsleyField) {
                    if (self.formSubmitted) {
                        var $fieldContainer = $(elem).closest('.input-container');
                        if (ParsleyField.valid) {
                            $fieldContainer.addClass('requirements-met');
                        } else {
                            $fieldContainer.removeClass('requirements-met');
                            self.$el.find('button[type="submit"]').removeClass(self.buttonLoaderClasses);
                        }
                    }
                },
                onFieldError: function (elem, ParsleyField) {
                    if (!ParsleyField.required.valid && self.formSubmitted) {
                        $(elem).closest('.input-container').removeClass('requirements-met');
                    } else {
                        $(elem).closest('.input-container').addClass('requirements-met');
                    }
                },
                onFieldSuccess: function (elem, ParsleyField) {
                    if (ParsleyField.required.valid && self.formSubmitted) {
                        $(elem).closest('.input-container').addClass('requirements-met');
                    }
                },
                onFormValidate: function () {
                    self.showFormErrors();
                }
            }
        });

        return this;

    }
});