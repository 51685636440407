/*globals define $ _ */
import Backbone from 'backbone';
import _ from 'underscore';
import LanguageSelectionTemplate from './languageSelectionViewTemplate.html';

export default Backbone.View.extend({
    template: _.template(LanguageSelectionTemplate),
    languages: [],
    curLangCode: '',
    events: {
        'click a': 'chooseLanguage'
    },
    initialize: function (options) {
        options = options || {};
        this.languages = options.languages || this.languages;
        var curLang = _.where(this.languages, { code: this._getCurrentLocale() })[0];
        if (curLang) {
            this.curLangCode = curLang.code;
        }
        this.render();
    },
    render: function() {
        this.$el.append(this.template({
            languages: this.languages,
            curLangCode: this.curLangCode
        }));
        return this;
    },
    chooseLanguage: function (event) {
        if (this._hasQueryStrings()) {

            event.preventDefault();
            event.stopPropagation();

            var selectedLang = $(event.currentTarget).data('wc.locale'),
                curUrl = location.href,
                newUrl;
                
            if(!selectedLang) {
                newUrl = $(event.currentTarget).attr("href");
            } else {
                if (curUrl.indexOf("wc.locale") > -1) {
                    newUrl = curUrl.replace(/(wc.locale=)[^\&]+/, '$1' + selectedLang);
                } else {
                    var startOfUrl = location.href.substr(0,location.href.indexOf('?')+1)
                    newUrl = location.href.replace(startOfUrl, startOfUrl + 'wc.locale=' + selectedLang + '&');
                }
            }
            window.location = newUrl;

        }
    },
    _hasQueryStrings: function () {
        return /[?&]/.test(location.href);
    },
    _getCurrentLocale: function () {
        return this._getQueryParams()['wc.locale'] || this._getCookie('wc.locale');
    },
    _getQueryParams: function () {
        if (document.location.search) {
            var queries = {};
            $.each(document.location.search.substr(1).split('&'),function(c,q){
                var i = q.split('=');
                queries[i[0].toString()] = i[1].toString();
            });
            return queries;
        } else {
            return '';
        }
    },
    _getCookie: function (name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }
        return null;
    }
});